<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="bgTitle">
        <div>智慧一体化云平台</div>
        <div>
          覆盖固定资产、办公、采购超盘点、维保的全生命周期移动化、数字化管理
        </div>
      </div>
    </div>
    <div class="mainauto">
      <el-tabs :tab-position="tabPosition" v-model="activeName">
        <el-tab-pane label="设备管理" name="devicemanage"><device-manage></device-manage></el-tab-pane>
        <el-tab-pane label="物资管理" name="materialmanage"><material-manage></material-manage></el-tab-pane>
        <el-tab-pane label="教学管理" name="teachingmanage"><teaching-manage></teaching-manage></el-tab-pane>
        <el-tab-pane label="实验室管理" name="laboratorymanage"><laboratory-manage></laboratory-manage></el-tab-pane>
        <el-tab-pane label="数据分析管理" name="datamanage"><data-manage></data-manage></el-tab-pane>
         <el-tab-pane label="采购管理" name="purchasemanage"><purchase-manage></purchase-manage></el-tab-pane>
        <el-tab-pane label="人力资源管理" name="manpowermanage"><manpower-manage></manpower-manage></el-tab-pane>
        <el-tab-pane label="OA办公管理" name="workmanage"><work-manage></work-manage></el-tab-pane>
        <el-tab-pane label="绩效管理" name="performancemanage"><performance-manage></performance-manage></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import DataManage from '../components/productcore/DataManage';
import DeviceManage from "../components/productcore/DeviceManage";
import LaboratoryManage from '../components/productcore/LaboratoryManage';
import ManpowerManage from '../components/productcore/ManpowerManage';
import MaterialManage from '../components/productcore/MaterialManage';
import PerformanceManage from '../components/productcore/PerformanceManage';
import PurchaseManage from '../components/productcore/PurchaseManage';
import TeachingManage from '../components/productcore/TeachingManage';
import WorkManage from '../components/productcore/WorkManage';
export default {
  name: "product-core",
  components: {DeviceManage,MaterialManage,TeachingManage,LaboratoryManage,DataManage,PerformanceManage,ManpowerManage,WorkManage,PurchaseManage,},
  data() {
   
 return {
      tabPosition: "left",
      activeName: "",
      activeIndex: "1",
      title: "",
      bglist: [
        { id: 0, url: require("@/assets/product/bg1.png") },
        { id: 1, url: require("@/assets/product/bg2.png") },
      ],
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.activeName = route.meta.title;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  line-height: 50px;
  text-align: center;
}
.bgTitle > div:nth-child(1) {
  font-size: 48px;
}
.bgTitle > div:nth-child(2) {
  font-size: 14px;
}
.img {
  width: 100%;
  height: 100%;
}
.mainauto {
  width: 1200px;
  margin: 0 auto 56px;
  text-align: center;
}
.mainauto >>> .el-tabs--left .el-tabs__item.is-left{
  border-bottom: 2px solid #d8d8d8;
}
.mainauto >>> .el-tabs--left .el-tabs__item.is-left:last-child{
  border-bottom: none;
  height:132px;
}

.mainauto >>> .el-tabs__item{
  height:64px;
  line-height: 64px;
  font-size: 20px;
 color:#505050;
}
.mainauto >>> .el-tabs--left .el-tabs__item.is-left:nth-child(2){
  height:90px;
  line-height:110px;
}
.mainauto >>> .el-tabs__item.is-active{
  color: #131313;
}
.mainauto >>> .el-tabs__active-bar{
  display:none;
}
.mainauto >>> .el-tabs--left .el-tabs__header.is-left{
  margin-right: 0;
}
.mainauto >>> .el-tabs__content{
  padding:0 90px;
}
</style>