<template>
  <div>
    <div>
      <div class="mainTitle">信息填报</div>
      <div class="content">
        教职工人员进行参评，按实际情况填报个人基本信息与履历信息。填写个人基本信息、上传
        照片、上传相应证明材料。
      </div>
      <img src="@/assets/productcore/jixiao1.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">业绩填报</div>
      <div class="content">
        指标分类/专业分类/职责分类/<br />类型分类/指标展示/分门别类/快速查找。
      </div>
      <img src="@/assets/productcore/jixiao2.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">业绩评审</div>
      <div class="content">
        对参评人员填报的信息、业绩细项进行审评价;<br />
        与事实情况不符的指标在线修改。
      </div>
      <img src="@/assets/productcore/jixiao3.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">业绩公示</div>
      <div class="content">
        评审结果全程公示，参评人员可对自己、其他参评人员进行申诉，记录留痕。
      </div>
      <img src="@/assets/productcore/jixiao4.png" alt="" />
    </div>
        <div>
      <div class="mainTitle">参评分析</div>
      <div class="content">
       参评职位统计、参评部门统计、考核分数统计、图形样例分析、下钻细则分析等。
      </div>
      <img src="@/assets/productcore/jixiao5.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "performance-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 32px;
  letter-spacing: 0.2px;
}
img {
  width: 86%;
}
</style>